"use client";

import { useEffect, useContext, useMemo } from "react";
import { AuthContext, ConfigContextV2, I18nextProvider } from "@finbackoffice/site-core";
import { CommonTranslationsContext } from "../../contexts";
import { NotFound } from "components/pages/404/NotFound";

const NotFoundError404 = ({
    token,
    translations,
}: {
    token: string;
    translations: Record<string, any>;
}) => {
    const translationsContext = useContext(CommonTranslationsContext);
    const { loadSiteConfig } = useContext(ConfigContextV2);
    const { setToken } = useContext(AuthContext);

    useEffect(() => {
        if (token) {
            setToken(token);
        }
        loadSiteConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo(
        () => ({
            ...translationsContext.translations,
            ...translations,
        }),
        [translations, translationsContext.translations],
    );

    return (
        <I18nextProvider translations={value}>
            <NotFound statusCode={404} />
        </I18nextProvider>
    );
};

export default NotFoundError404;
